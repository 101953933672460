<template>
  <div>
    <div v-if="invoice.pdfFilename === undefined">
      <strong class="black--text ml-8">{{ invoice.number }}</strong>
    </div>
    <div v-else>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="invoice.isInvoice ? 'red' : 'green'" @click='getFileContent(invoice.id)'> mdi-file-download-outline </v-icon>
        </template>
        <span> {{ invoice.isInvoice ? $t('DOWNLOAD_INVOICE') : $t('DOWNLOAD_CREDIT_NOTE') }} </span>
      </v-tooltip>
      <strong class="black--text ml-2">{{ invoice.number }}</strong>
    </div>
  </div>
</template>

<script>

import invoiceService from '@/services/invoiceService';
import oneOffPaymentService from '@/services/oneOffPaymentService';

export default {
  props: {
    invoice: {
      type: Object,
      required: true
    },
    oneOffMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async getFileContent (invoiceId) {
      const { data } = await this.getDocumentFileContent(invoiceId);
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    async getDocumentFileContent (invoiceId) {
      if (this.oneOffMode) {
        return await oneOffPaymentService.getOneOffDocumentFileContent(invoiceId);
      } else {
        return await invoiceService.getDocumentFileContent(invoiceId);
      }
    }
  }
};
</script>