<template>
  <strong
    v-if="isInvoice"
    class="black--text"
  >
    {{ amount | currency(currency) }}
  </strong>
  <strong
    v-else
    class="green--text text--darken-4"
  >
    {{ -amount | currency(currency) }}
  </strong>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [
    numberMixin
  ],
  props: {
    amount: {
      type: Number,
      required: true
    },
    isInvoice: {
      type: Boolean,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  }
};
</script>
