import Service from './Service';

class PaymentMeansService extends Service {
  /**
   * Returns list of enabled payment means
   * @param {String} creditorId - creditor id (ex: fb6664af-7e89-4df9-bd8e-54ec6ddeef17)
   *
   * @returns {Object} List of payment means
   */
  getEnabledPaymentMeans (creditorId) {
    return this.instanceNoAuth.get(`invoiceapi/v1/payment-means/enabled?creditorId=${creditorId}`);
  }

  /**
   * Returns the list of paymentMeans for a Creditor Account
   * @param {String} creditorId - id of the creditor user
   *
   * @returns {Object} List of PaymentMeans
   */
  getPaymentMeansByCreditorId (creditorId) {
    return this.instance.get('invoiceapi/public/v2/payment-means', {
      params: {
        creditorId: creditorId
      }
    });
  }
}

export default new PaymentMeansService();