import moment from 'moment-timezone';

export default {
  data () {
    return {
      rules: {
        emailRules: value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(value) || this.$t('ERROR_EMAIL_INVALID'),
        creditorAccountsGroupsRules: value => (value && value.length > 0) || this.$t('ERROR_REQUIRED_FIELD'),
        dateRules: v => !v || v.length === 0 || this.validateDate(v) || this.$t('ERROR_INVALID_DATE_FORMAT'),
        nameRules: value => (value && value.length <= 100) || this.$t('ERROR_VALUE_OVER_100_CHARACTERS'),
        nameRegex: value => /^[A-Za-zÀ-ÖÙ-öù-ÿ. '-]{1,100}$/.test(value) || this.$t('ERROR_NAME_INVALID'),
        mobilePhoneRules: regex => {
          return value => !value || regex.test(value.slice().replace(/\s+/g, '')) || this.$t('ERROR_MOBILE_PHONE_INVALID');
        },
        requiredFieldRules: value => !!value || this.$t('ERROR_REQUIRED_FIELD'),
        positiveAmountValue: value => (value && value.replaceAll('.', '').replace(',', '.') > 0) || this.$t('ERROR_AMOUNT_MUST_BE_POSITIVE')
      }
    };
  },
  methods: {
    validateDate (v) {
      return /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(v) && moment(v, 'DD/MM/YYYY').isValid();
    }
  }
};