import Service from './Service';

class OneOffPaymentService extends Service {
  getPaymentOperation (id) {
    return this.instanceNoAuth.get(`invoiceapi/v1/one-off-payment-operations/${id}`);
  }

  // Méthode utilisée nulle part
  getPaymentOperationByInvoiceId (invoiceId) {
    return this.instanceNoAuth.get(`invoiceapi/v1/one-off-payment-operations/payment-operations/invoices/${invoiceId}`);
  }

  getPaymentOperationByReference (transactionReference) {
    return this.instanceNoAuth.get(`invoiceapi/v1/one-off-payment-operations/transactions/${transactionReference}`);
  }

  createPaymentOperation ({ id, basket, creditorId, debtorId, languageCode, paymentMean }) {
    const documents = basket.invoices.map(invoice => ({
      amount: invoice.balance.amount,
      invoiceId: invoice.id
    }));

    return this.instanceNoAuth.post(`invoiceapi/v1/one-off-payment-operations/${id}`, {
      documents,
      amount: basket.balance.amount,
      creditorId,
      debtorId,
      paymentMeanType: paymentMean.type,
      paymentMeanId: paymentMean.id,
      languageCode,
      oneOffPaymentOperationId: id
    });
  }

  getOneOffDocumentFileContent (invoiceId) {
    return this.instanceNoAuth.get(`invoiceapi/v1/one-off-payment-operations/invoices/${invoiceId}/content`, {
      responseType: 'blob'
    });
  }
}

export default new OneOffPaymentService();
